import React from 'react';
import textCleanup from '$utils/textCleanup';
import { WrapMax } from '$components/Wraps';
import Heading1 from '$components/Heading1';
import FadeInSection from '$components/FadeInSection';
import { BlockMainHeadingProps } from './types';

const BlockMainHeading: React.FC<BlockMainHeadingProps> = ({ text }) => (
  <WrapMax>
    <FadeInSection>
      <Heading1 primary center>
        {textCleanup(text)}
      </Heading1>
    </FadeInSection>
  </WrapMax>
);

export default BlockMainHeading;
